import { swiperInit, grubScrolling } from './helper'

swiperInit({
  perView: 1,
  className: '.info-list__swiper',
  spaceBetween: 12,
  hide: true,
  breakpoints: {
    780: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    1025: {
      slidesPerView: 3,
      spaceBetween: 0,
    }
  },
  auto: true,
  pagination: {
    el: '.info-list__pagination',
    clickable: true,
  }
})

const activityBlock = document.querySelector('.activity')
projectSlideChange()

swiperInit({
  init: false,
  perView: 1,
  className: '.activity-slider',
  nextBtnClass: '.activity-slider__btn--right',
  prevBtnClass: '.activity-slider__btn--left',
  swiperCurrentClass: '.activity-slider__current',
  swiperCountClass: '.activity-slider__total',
  spaceBetween: 12,
  hide: true,
  breakpoints: {
    780: {
      spaceBetween: 1,
    },
  },
  pagination: {
    el: '.activity-slider__pagination',
    clickable: true,
  },
  slideChangeCallback: (ind, e) => {
    const before = document.querySelector('.activity__before')

    activityBlock.dataset.current = ind
    const colorEl = e.slides[e.activeIndex]?.querySelector('[data-color]')
    if (colorEl && before) {
      before.style.borderTopColor = colorEl.dataset.color
      before.style.borderLeftColor = colorEl.dataset.color
    }
    projectSlideChange(ind)
  }
})

function projectSlideChange(ind) {
  if (activityBlock.classList.contains('animate')) {
    activityBlock.classList.remove('animate')
    activityBlock.classList.add('no-animate')
  } else {
    activityBlock.classList.add('animate')
    activityBlock.classList.remove('no-animate')
  }
}

grubScrolling('.analytic__types-wrap')
grubScrolling('.analytic__subtypes')
