import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const SOCIALS_SHARES = {
  'facebook': (url, title) => `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`,
  'twitter': (url, title) => `http://twitter.com/share?&url=${url}&title=${title}`,
  'vk': (url, title) => `http://vk.com/share.php?&url=${url}&title=${title}`,
}

export function socialsShareBlock({ wrap }) {
  const el = document.querySelector(wrap)

  el.addEventListener('click', (e) => {
    const typeElem = e.target.closest('[data-type]')
    if (typeElem) {
      e.preventDefault()
      const type = typeElem.dataset.type

      const shareLinkFunc = SOCIALS_SHARES[type]
      if (shareLinkFunc) {
        const link = encodeURI(window.location.href)
        const title = encodeURIComponent(document.title)

        const shareLink = shareLinkFunc(link, title)

        if (shareLink) window.open(shareLink, '', 'toolbar=0,status=0,width=626,height=436');
      }
    }
  })
}

export function swiperInit({ init, swiperCurrentClass, swiperCountClass, className, perView, spaceBetween, loop, nextBtnClass, prevBtnClass, direction, hide, breakpoints, pagination, slideChangeCallback }, sizes) {
  let slidesPerView = perView || 1

  if (sizes) {
    const wSize = getWindowSize()
    slidesPerView = sizes[wSize]?.perView
  }

  const space = spaceBetween

  let swiper
  let initSize;

  const opt = {
    init: init !== undefined ? init : true,
    direction: direction || 'horizontal',
    modules: [Navigation, Pagination],
    slidesPerView: slidesPerView,
    updateOnWindowResize: true,
    spaceBetween: space,
    loop: loop === undefined ? true : loop,
    breakpoints: breakpoints || {},
    navigation: {
      nextEl: nextBtnClass,
      prevEl: prevBtnClass,
    },
  }

  if (pagination) opt['pagination'] = pagination

  getSwiper()

  function getSwiper() {
    swiper = new Swiper(className, opt);

    swiper.on('resize', () => {
      if (!sizes) return

      const windowSize = getWindowSize()

      if (windowSize === initSize) return

      initSize = windowSize

      slidesPerView = sizes[initSize]?.perView || 1

      swiper.updateSize()
    })

    slideChange(swiper)

    swiper.on('slideChangeTransitionEnd', slideChange)

    swiper.on('slideChange', function (e) {
      if (slideChangeCallback) slideChangeCallback(e.realIndex, e)
    });

    if (!init) {
      const swiperCurrent = document.querySelector(swiperCurrentClass);
      const swiperCount = document.querySelector(swiperCountClass);

      if (!swiperCurrent || !swiperCount) return

      swiper.on('init', () => {
        swiperCount.innerHTML = swiper.slides.length;
        swiperCurrent.innerHTML = swiper.realIndex + slidesPerView;
      })

      swiper.on('activeIndexChange', (swiper, progress) => {
        swiperCurrent.innerHTML = swiper.realIndex + slidesPerView;
      })

      swiper.init();
    }

    return swiper
  }

  function slideChange(sw) {
    if (slidesPerView === 1) return

    const activeEl = sw.slides.find((e) => e.classList.contains('swiper-slide-active'))
    if (activeEl) {
      if (activeEl.previousElementSibling && hide) activeEl.previousElementSibling.style.opacity = 0;

      if (hide) {
        const elemOpacFunc = swiperHide(slidesPerView)

        elemOpacFunc(activeEl)
      }
    }
  }

  function swiperHide(count) {
    let c = 0

    function rec(el) {
      c += 1
      if (c > count) return
      el.style.opacity = 1;
      if (el.nextElementSibling) rec(el.nextElementSibling)
    }

    return rec
  }
}

export function getWindowSize() {
  const width = document.body.offsetWidth;

  if (width < 780) return 'small'
  else if (width < 1025) return 'medium'
  else return 'large'
}

export function adaptiveSwiper(opt, sizes) {
  swiperInit(opt, sizes)
}

export function grubScrolling(className, opt) {
  const slider = document.querySelector(className);

  if (!slider) return;

  let isDown = false;
  let startX;
  let scrollLeft;

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
  });
  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3;
    slider.scrollLeft = scrollLeft - walk;
  });

  if (opt) {
    const btnLeft = document.querySelector(opt.left)
    const btnRight = document.querySelector(opt.right)

    btnLeft.addEventListener('click', () => {
      const newVal = slider.scrollLeft - 300
      slider.scroll({
        left: newVal > 0 ? newVal : 0,
        behavior: 'smooth'
      })
    })

    btnRight.addEventListener('click', () => {
      const newVal = slider.scrollLeft + 300
      slider.scroll({
        left: newVal < slider.scrollWidth ? newVal : slider.scrollWidth,
        behavior: 'smooth'
      })
    })
  }
}

export function initToggleTags({ wrapClass, activeClass, togglerClass }) {
  const wraps = document.querySelectorAll(`.${wrapClass}`)

  wraps.forEach((w) => {
    w.addEventListener('click', (e) => {
      const isToggler = e.target.closest(`.${togglerClass}`)
      w.classList.remove('transitionend')

      if (isToggler) {
        w.classList.toggle(activeClass)
      }
    })

    w.addEventListener('transitionend', () => {
      if (w.classList.contains(activeClass)) {
        w.classList.add('transitionend')
      }
    })
  })
}

export function autoScroll(queryStrings) {
  if (Array.isArray(queryStrings)) {
    queryStrings.forEach((query) => {
      if (location.search.includes(query)) {
        const el = document.querySelector(`[data-query-type="${query}"]`)
        if (el) el.scrollIntoView()
      }
    })
  }
}

export function tabsHandler({ wrapClass, activeTrigger, removeTrigger, activeTarget, func }) {
  const tabTriggers = document.querySelectorAll(`.${wrapClass} [data-tab-trigger]`)

  tabTriggers.forEach((t) => {
    t.addEventListener('click', () => {
      if (t.classList.contains(activeTrigger)) return
      const val = t.dataset.tabTrigger
      onClick(val, t)
    })

    if (t.dataset.queryType && location.search.includes(t.dataset.queryType)) {
      onClick(t.dataset.tabTrigger, t)
      t.scrollIntoView()
    }
  })

  function onClick(val, t) {
    const activeTriggerElem = document.querySelector(`.${wrapClass} .${activeTrigger}`)
    activeTriggerElem?.classList.remove(activeTrigger)
    if (removeTrigger) activeTriggerElem?.classList.add(removeTrigger)

    document.querySelector(`.${wrapClass} .${activeTarget}`)?.classList.remove(activeTarget)

    t.classList.add(activeTrigger)
    if (removeTrigger) t.classList.remove(removeTrigger)

    document.querySelector(`.${wrapClass} [data-tab-target='${val}']`)?.classList.add(activeTarget)
    if (func) func(val)
  }
}

export function modalFunc({ activatorClass, closeBtnClass, targetClass, activeClass, openFunc, closeFunc }) {
  const activator = document.querySelectorAll(activatorClass)
  const target = document.querySelector(targetClass)
  const close = document.querySelectorAll(closeBtnClass)
  activator.forEach((e) => {
    e.addEventListener('click', () => {
      target.classList.toggle(activeClass)
      if (openFunc) openFunc()
    })
  })

  close.forEach((e) => {
    e.addEventListener('click', (e) => {
      e.preventDefault()
      target.classList.toggle(activeClass)
      if (closeFunc) closeFunc()
    })
  })

  return () => {
    target.classList.remove(activeClass)
    if (closeFunc) closeFunc()
  }
}

export function autoExpand(field) {
  field.style.height = 'inherit'

  const computed = window.getComputedStyle(field)

  const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
    + field.scrollHeight
    + parseInt(computed.getPropertyValue('border-bottom-width'), 10)

  field.style.height = height + 'px'
}

export function txtAutoExpand(selector) {
  const txtElem = document.querySelector(selector)

  txtElem?.addEventListener('input', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea') return
    autoExpand(event.target)
  }, false)
}

export function getSelectedText() {
  var selectedText = ''

  if (window.getSelection) {
    selectedText = window.getSelection()
  }
  else if (document.getSelection) {
    selectedText = document.getSelection()
  }
  else if (document.selection) {
    selectedText =
      document.selection.createRange().text
  }

  return selectedText
}

export function counterAnim(className) {
  const elems = document.querySelectorAll(className)

  elems.forEach((e) => {
    const dataVal = +e.dataset.value || 0
    const speed = +e.dataset.speed || dataVal / 10
    const time = +e.dataset.time || 300
    e.textContent = 0

    timerAnim(
      () => {
        if (+e.textContent + speed >= dataVal) e.textContent = dataVal
        else e.textContent = (+e.textContent + speed).toFixed(1)
      },
      () => {
        return !(+e.textContent >= dataVal)
      },
      time
    )
  })
}

function timerAnim(func, conditionFunc, ms = 300) {
  setTimeout(() => {
    func()
    if (conditionFunc()) timerAnim(func, conditionFunc, ms)
  }, ms)
}